import React from "react";
import styles from "./FormSubmitted.module.scss";
import { IconSizes } from "../../../enums/icon-sizes";
import FormSubmitMailIcon from "../../../images/icons/v1/form-submit-icon.inline.svg";
import AFGCButton from "../afgc-button/AFGCButton";
import cx from "classnames";
import SVGContainer from "../../commons/svg-container/SVGContainer";

interface Props {
  closePopUp: Function;
}

const FormSubmitted: React.FC<Props> = (props: Props) => {
  return (
    <section className={cx("column", styles.formSubmitted)}>
      <SVGContainer iconSize={IconSizes.IC__formSubmit}>
        <FormSubmitMailIcon />
      </SVGContainer>
      <h4 className="d5">Message successfuly sent!</h4>
      <p className="p2">Thank you, I will contact you as soon as possible</p>
      <AFGCButton onClick={props.closePopUp} className="p2">
        Okay, I see
      </AFGCButton>
    </section>
  );
};

export default FormSubmitted;
