import React, { useState } from "react";
import cx from "classnames";
import styles from "./contactMe.module.scss";
import MailIcon from "../../../../images/icons/mail.inline.svg";
import PhoneIcon from "../../../../images/icons/phone.inline.svg";
import CalendlyIcon from "../../../../images/icons/calendly.inline.svg";
import LocationIcon from "../../../../images/icons/v2/location.inline.svg";
import FacebookIcon from "../../../../images/icons/facebook.inline.svg";
import InstagramIcon from "../../../../images/icons/instagram.inline.svg";
import PinterestIcon from "../../../../images/icons/pinterest.inline.svg";
import AsteriskIcon from "../../../../images/icons/asterisk.inline.svg";
import { ContactLinks } from "../../../../utils/contact-links";
import { LOCATIONS } from "../../../../utils/constants";
import { IconSizes } from "../../../../enums/icon-sizes";
import { SocialLinks } from "../../../../utils/social-links";
import { RouteLinks } from "../../../../utils/route-links";
import { MenuOverlayOptions } from "../../../../enums/menu-overlay-options";
import SocialCard from "../../contact-me/social-card/SocialCard";
import StudioNinja from "../../../commons/StudioNinja";
import FormSubmitPopup from "../../form-submit-popup/FormSubmitPopup";
import AFGCAnchor from "../../../commons/afgc-anchor/AFGCAnchor";
import Calendly from "../../../commons/calendly/Calendly";
import SVGContainer from "../../../commons/svg-container/SVGContainer";
import Layout from "../../layout/Layout";
import { graphql, Link, useStaticQuery } from "gatsby";
import SEO from "../../../commons/seo";

const query = graphql`
  {
    seo: markdownRemark(
      fields: {
        category: { eq: "seo" }
        relativePath: { eq: "seo__contactMe" }
      }
    ) {
      frontmatter {
        metaTitle
        metaDescription
        metaKeywordList
        metaImageName
      }
    }
  }
`;

const ContactMe: React.FC = () => {
  const [submitPressed, setSubmitPressed] = useState(false);

  const queryResult = useStaticQuery(query);

  const {
    metaTitle,
    metaDescription,
    metaKeywordList,
    metaImageName,
  } = queryResult.seo.frontmatter;

  return (
    <Layout menuSelected={MenuOverlayOptions.CONTACT_ME}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywordList}
        imageUrl={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        pageUrl={RouteLinks.contact}
      />
      <main className={cx("column", styles.container)} data-test-e2e="contact-container">
        <div className={styles.container__asterisk}>
          <AsteriskIcon />
        </div>
        <section className={cx("column", styles.formSection)}>
          <h1 className={styles.formSection__padding}>Contact me</h1>
          <h6 className={styles.formSection__padding}>
            Let’s capture some memories together.
          </h6>
          <p
            className={cx(
              styles.formSection__desc,
              styles.formSection__padding
            )}
          >
            Drop me a line using the form below to enter your details – I’ll get
            back to you within 48 hours and we can go from there! – or hit me up
            directly on email, phone or social. I’m always around!
          </p>
          <StudioNinja />
          <div
            className={cx(
              styles.formSection__social,
              styles.formSection__padding
            )}
          >
            <SocialCard
              label="Facebook"
              ariaLabel="Share on Facebook"
              link={SocialLinks.facebook}
            >
              <FacebookIcon />
            </SocialCard>
            <SocialCard
              label="Instagram"
              ariaLabel="Share on Instagram"
              link={SocialLinks.instagram}
            >
              <InstagramIcon />
            </SocialCard>
            <SocialCard
              label="Pinterest"
              ariaLabel="Share on Pinterest"
              link={SocialLinks.pinterest}
            >
              <PinterestIcon />
            </SocialCard>
          </div>
        </section>
        <section className={styles.stayTuned}>
          <h3 className={styles.stayTuned__title}>Find me</h3>
          <div className={styles.stayTuned__info}>
            <section className={cx("column", styles.infoCard)}>
              <h5 className={styles.infoCard__title}>Contact info</h5>
              <div className={styles.infoCard__iconRow}>
                <SVGContainer>
                  <MailIcon />
                </SVGContainer>
                <AFGCAnchor
                  ariaLabel="Mail Link"
                  url={`mailto:${ContactLinks.mail}`}
                >
                  {ContactLinks.mail}
                </AFGCAnchor>
              </div>
              <div className={styles.infoCard__iconRow}>
                <SVGContainer>
                  <PhoneIcon />
                </SVGContainer>
                <AFGCAnchor
                  ariaLabel="Phone Link"
                  url={`tel:${ContactLinks.phone}`}
                >
                  {ContactLinks.phoneDisplay}
                </AFGCAnchor>
              </div>
              <div className={styles.infoCard__iconRow}>
                <SVGContainer>
                  <CalendlyIcon />
                </SVGContainer>
                <Calendly text="Schedule a call" />
              </div>
            </section>
            <section className={cx("column", styles.infoCard)}>
              <h5 className={styles.infoCard__title}>Locations</h5>
              {LOCATIONS.map((item, index) => (
                <li key={index} className={styles.infoCard__location}>
                  <SVGContainer iconSize={IconSizes.IC__24}>
                    <LocationIcon />
                  </SVGContainer>
                  <p>{item}</p>
                </li>
              ))}
            </section>
          </div>
          <p className={styles.stayTuned__footer}>
            Or you can visit&nbsp;
            <Link to={RouteLinks.faqs} data-test-e2e="faqs">
              <span>FAQ page</span>
            </Link>
            &nbsp;to find the answers to common questions.
          </p>
        </section>
        <FormSubmitPopup
          closePopUp={() => setSubmitPressed(false)}
          isOpen={submitPressed}
        />
      </main>
    </Layout>
  );
};

export default ContactMe;
