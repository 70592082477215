import React from "react";
import cx from "classnames";
import styles from "./AFGCButton.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: Function;
  type?: "submit" | "reset" | "button";
  variant?: "pink" | "black";
  disabled?: boolean;
}

const AFGCButton: React.FC<Props> = (props: Props) => {
  let className = [styles.AFGCButton];
  if (props.variant === "pink") {
    className.push(styles.pinkVariant);
  }
  return (
    <button
      disabled={props.disabled}
      type={props.type}
      className={cx(className.join(" "), props.className ?? "")}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.children}
    </button>
  );
};

AFGCButton.defaultProps = {
  variant: "black",
  type: "button",
};

export default AFGCButton;
