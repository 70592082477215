import React from "react";
import styles from "./form-submit-dialog.module.scss";
import CrossIcon from "../../../images/icons/cross-icon.inline.svg";
import { IconSizes } from "../../../enums/icon-sizes";
import CustomDialog from "../../commons/custom-dialog/CustomDialog";
import FormSubmitted from "../form-submitted/FormSubmitted";
import SVGContainer from "../../commons/svg-container/SVGContainer";

interface Props {
  closePopUp: () => void;
  isOpen: boolean;
}

const FormSubmitDialog: React.FC<Props> = (props: Props) => {
  return (
    <CustomDialog closePopUp={props.closePopUp} isOpen={props.isOpen}>
      <div className={styles.formDialog}>
        <section className={styles.formDialog__heading}>
          <div onClick={props.closePopUp}>
            <SVGContainer iconSize={IconSizes.IC__filter}>
              <CrossIcon />
            </SVGContainer>
          </div>
        </section>
        <FormSubmitted closePopUp={props.closePopUp} />
      </div>
    </CustomDialog>
  );
};

export default FormSubmitDialog;
