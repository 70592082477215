import React from "react";
import { IconSizes } from "../../../../enums/icon-sizes";
import AFGCAnchor from "../../../commons/afgc-anchor/AFGCAnchor";
import SVGContainer from "../../../commons/svg-container/SVGContainer";
import styles from "./SocialCard.module.scss";
import cx from "classnames";

interface Props {
  label: string;
  ariaLabel: string;
  link: string;
  children: React.ReactNode;
}

const SocialCard: React.FC<Props> = (props: Props) => {
  return (
    <AFGCAnchor
      ariaLabel={props.ariaLabel}
      url={props.link}
      className={cx("column", styles.SocialCard)}
    >
      <div className={styles.SocialCard__icon}>
        <SVGContainer iconSize={IconSizes.IC__30}>
          {props.children}
        </SVGContainer>
      </div>
      <p className={styles.SocialCard__desc}>{props.label}</p>
    </AFGCAnchor>
  );
};

export default SocialCard;
