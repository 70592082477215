import React from "react";
import styles from "./AFGCButton.module.scss";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: Function;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  dataTestE2E?: string;
}

const AFGCButton: React.FC<Props> = (props: Props) => {
  const { type = "button" } = props;

  return (
    <button
      disabled={props.disabled}
      className={cx(styles.AFGCButton, props.className ?? "")}
      type={type}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      data-test-e2e={props.dataTestE2E}
    >
      {props.children}
    </button>
  );
};

export default AFGCButton;
