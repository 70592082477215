import React, { Fragment, useState, useEffect } from "react";
import styles from "./StudioNinja.module.scss";
import Ninja from "./NinjaComponent";
import cx from "classnames";

interface Props {
  columnClassName?: string;
  rowClassName?: string;
}

const StudioNinja: React.FC<Props> = (props: Props) => {
  const [loadIFrame, setLoadIFrame] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadIFrame(true);
    }, 100);
  }, []);

  if (loadIFrame) {
    return (
      <Fragment>
        <Ninja
          scriptSrc="https://app.studioninja.co/client-assets/form-render/assets/scripts/iframeResizer.js"
          iFrameId="sn-form-jxrkc"
          iFrameSrc="https://app.studioninja.co/contactform/parser/0a800fc8-6fb6-108b-816f-b7ca9d1b02d4/0a800fc8-7ac3-1c63-817a-cd56929476bd"
          className={cx(styles.container__column, props.columnClassName ?? "")}
        />
        <Ninja
          scriptSrc="https://app.studioninja.co/client-assets/form-render/assets/scripts/iframeResizer.js"
          iFrameId="sn-form-rgxfp"
          iFrameSrc="https://app.studioninja.co/contactform/parser/0a800fc8-6fb6-108b-816f-b7ca9d1b02d4/0a800fc8-7ac3-1c63-817a-cd22112774d5"
          className={cx(styles.container__row, props.rowClassName ?? "")}
        />
      </Fragment>
    );
  }

  return null;
};

export default StudioNinja;
