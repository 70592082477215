import React from "react";
import ContactMe1 from "../components/v1/pages/contact-me";
import ContactMe2 from "../components/v2/pages/contact-me";

const ContactMe = () => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <ContactMe1 />;
  } else {
    return <ContactMe2 />;
  }
};

export default ContactMe;
