import React, { useEffect } from "react";
import styles from "./Ninja.module.scss";
import cx from "classnames";

interface Props {
  className: string;
  iFrameId: string;
  iFrameSrc: string;
  scriptSrc: string;
}

const Ninja: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = props.scriptSrc;
    script.type = "text/javascript";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <iframe
      className={cx(styles.contact, props.className ?? "")}
      id={props.iFrameId}
      src={props.iFrameSrc}
      allowFullScreen
    />
  );
};

export default Ninja;
