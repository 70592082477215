import React from "react";
import styles from "./FormSubmitPopup.module.scss";
import CustomDialog from "../../commons/custom-dialog/CustomDialog";
import CrossIcon from "../../../images/icons/cross-icon.inline.svg";
import { IconSizes } from "../../../enums/icon-sizes";
import FormSubmitMailIcon from "../../../images/icons/check-circle.inline.svg";
import AFGCButton from "../afgc-button/AFGCButton";
import SVGContainer from "../../commons/svg-container/SVGContainer";

interface Props {
  closePopUp: () => void;
  isOpen: boolean;
}

const FormSubmitPopup: React.FC<Props> = (props: Props) => {
  return (
    <CustomDialog closePopUp={props.closePopUp} isOpen={props.isOpen}>
      <div className={styles.FormSubmit}>
        <section className={styles.FormSubmit__heading}>
          <div onClick={props.closePopUp}>
            <SVGContainer
              iconSize={IconSizes.IC__24}
              className={styles.FormSubmit__cross}
            >
              <CrossIcon />
            </SVGContainer>
          </div>
        </section>
        <section className={styles.FormSubmit__submitted}>
          <SVGContainer iconSize={IconSizes.IC__60}>
            <FormSubmitMailIcon />
          </SVGContainer>
          <h4>Thank you!</h4>
          <p>Your message has been successfully sent</p>
          <AFGCButton onClick={props.closePopUp}>Got it!</AFGCButton>
        </section>
      </div>
    </CustomDialog>
  );
};

export default FormSubmitPopup;
